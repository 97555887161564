import styled from 'styled-components';
import { device } from 'styles/device';
import { ButtonLink } from 'components/Buttons/ButtonLink';
import { H2 } from 'components/globals/TypographyElements';

export const Title = styled(H2)`
  font-weight: normal;
  text-align: center;
  margin: 25px 0 17px 0;

  @media ${device.laptop} {
    margin: 25px 0 15px 0;
  }
`;

export const Content = styled.div`
  max-width: 1360px;
  margin: 35px auto 65px auto;
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    margin: 55px auto 90px auto;
  }
`;

export const FeedbackButton = styled(ButtonLink)`
  margin: 0 auto;

  @media ${device.laptop} {
    width: 300px;
  }
`;
