import { Client } from 'prismic-configuration';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// components
import Layout from 'components/Layout';
// slices
// import ReviewSection from 'components/slices/ReviewSection';
import OverviewSection from 'components/slices/OverviewSection';
import HowDoesItWorkSection from 'components/slices/HowDoesItWorkSection';
import CalendarSection from 'components/slices/CalendarSection';
import CommonQuestionsSection from 'components/slices/CommonQuestionsSection';
import BrowseMenuSection from 'components/slices/BrowseMenuSection';
import FinalSection from 'components/slices/FinalSection';
// animations
import { ParallaxBackground } from 'components/animationHelpers/ParallaxBox';
import FadeInUpBoxPages from 'components/animationHelpers/FadeInBoxPages';
// hooks
import useScrollTo from 'hooks/useScroll';
// HOC's
import withCookies from 'HOCs/withCookies';
import FeedbackSection from 'components/slices/FeedbackSection';

const PageContent = styled.div`
  overflow-x: hidden;
`;

const HomePage = ({ homePage, layoutProps }) => {
  // scroll to the top
  useScrollTo(0, 0);

  const pageContent = homePage?.data.body.map((slice, index) => {
    switch (slice.slice_type) {
      case 'feedback':
        return <FeedbackSection key={index} items={slice.items} primary={slice.primary} />;
      case 'overview':
        return <OverviewSection key={index} items={slice.items} primary={slice.primary} />;
      case 'how_does_it_work':
        return <HowDoesItWorkSection key={index} items={slice.items} primary={slice.primary} />;
      // case 'review':
      //   return <ReviewSection key={index} primary={slice.primary} />;
      case 'browse_menu':
        return <BrowseMenuSection key={index} items={slice.items} primary={slice.primary} />;
      case 'calendar':
        return <CalendarSection key={index} items={slice.items} primary={slice.primary} />;
      case 'questions':
        return <CommonQuestionsSection key={index} items={slice.items} primary={slice.primary} />;
      case 'final':
        return <FinalSection key={index} primary={slice.primary} />;
      default:
        return null;
    }
  });

  return (
    <Layout {...layoutProps} seo={homePage?.data}>
      <FadeInUpBoxPages yOffset={64} duration={0.7}>
        <>
          <ParallaxBackground initial="hidden" animate="show" exit="hidden" />
          <PageContent>{pageContent}</PageContent>
        </>
      </FadeInUpBoxPages>
    </Layout>
  );
};

HomePage.propTypes = {
  homePage: PropTypes.object,
  layoutProps: PropTypes.object,
};

HomePage.getInitialProps = async ({ req }) => {
  try {
    // parallel requests to the prismic
    const [homePage, reusableSlices, layoutProps] = await Promise.all([
      Client(req).getSingle('homepage'),
      Client(req).getSingle('reusable-slices'),
      Client(req).getSingle('layout'),
    ]);

    // get slices from array
    const [browseMenu, questions] = reusableSlices.data.body;

    // filter, not common questions
    questions.items = [...questions.items.filter((item) => !item.is_common)];

    // add slices to plans content
    // homePage.data.body.splice(3, 0, browseMenu);
    // homePage.data.body.splice(5, 0, questions);
    // homePage.data.body.splice(6, 0, final);
    homePage.data.body.splice(3, 0, browseMenu);
    homePage.data.body.splice(6, 0, questions);

    return {
      homePage,
      layoutProps,
    };
  } catch (error) {
    return { error: true };
  }
};

export default withCookies(HomePage);
