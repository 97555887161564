import Link from 'next/link';
import PropTypes from 'prop-types';
// components
import { ButtonLink } from 'components/Buttons/ButtonLink';
import UnderlineBox from 'components/animationHelpers/UnderlineBox';
import { Container, FlexRow, Icon } from 'components/globals';
import { AnimatedText, UnderlinedText, Text } from 'components/globals/TypographyElements';
import SVGIcon from 'components/SVGIcon';
import { IntersectionObserver } from 'components/animationHelpers/IntersectionObserver';
// hooks
import useTransferQueryParams from 'hooks/useTransferQueryParams';
// styles
import car from 'assets/icons/car-icon.svg';
import list from 'assets/icons/list-icon.svg';
import {
  Content,
  ButtonWrapper,
  Title,
  IconWrapper,
  Description,
  StarsWrapper,
  ImagesWrapper,
  FirstPlate,
  SecondPlate,
  ThirdPlate,
} from './style';

const OverviewSection = ({ primary }) => {
  const queryForTransfer = useTransferQueryParams();

  return (
    <section>
      <IntersectionObserver>
        <Container>
          <Content>
            <Description>
              <Title center>{primary.title}</Title>

              <FlexRow padding="10px 0">
                <IconWrapper>
                  <Icon src={list} alt="list" size={28} sizeLg={46} />
                </IconWrapper>
                <AnimatedText>
                  Healthy eating made&nbsp;
                  <UnderlineBox delayOrder={1}>
                    <UnderlinedText>simple</UnderlinedText>
                  </UnderlineBox>
                </AnimatedText>
              </FlexRow>

              <FlexRow padding="10px 0">
                <IconWrapper>
                  <Icon src={car} alt="car" size={40} sizeLg={61} />
                </IconWrapper>
                <div>
                  <AnimatedText>
                    Preservative-free meals,
                    <UnderlineBox primary delayOrder={2}>
                      <UnderlinedText> delivered weekly&nbsp;</UnderlinedText>
                    </UnderlineBox>
                    and ready
                  </AnimatedText>
                  <Text>in three minutes</Text>
                </div>
              </FlexRow>

              <ButtonWrapper>
                <Link scroll={false} href={{ pathname: '/checkout/2', query: queryForTransfer }} passHref>
                  <ButtonLink>{primary.button}</ButtonLink>
                </Link>
              </ButtonWrapper>

              <FlexRow>
                <StarsWrapper style={{ visibility: 'hidden' }}>
                  <div>
                    {[...Array(5).keys()].map((_, index) => (
                      <SVGIcon key={index} name="stars-group" width={24} fill="#00b101" />
                    ))}
                  </div>
                  <div>
                    <Text size="small" bold>
                      Rated 4.9/5 Stars
                    </Text>
                    <Text size="small" bold>
                      on Facebook and Google
                    </Text>
                  </div>
                </StarsWrapper>
              </FlexRow>
            </Description>
            <ImagesWrapper>
              <FirstPlate src={primary.first_plate.url} alt="" />
              <SecondPlate src={primary.second_plate.url} alt="" />
              <ThirdPlate src={primary.third_plate.url} alt="" />
            </ImagesWrapper>
          </Content>

          {/* Animation Example */}
          {/* <FadeInUpBox yOffset={64} duration={1}>
          <p>Get started</p>
        </FadeInUpBox> */}
        </Container>
      </IntersectionObserver>
    </section>
  );
};

OverviewSection.propTypes = {
  primary: PropTypes.object,
};

export default OverviewSection;
