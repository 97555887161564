import Link from 'next/link';
import PropTypes from 'prop-types';
// components
import GridGallery from 'components/GridGallery';
import { IntersectionObserver } from 'components/animationHelpers/IntersectionObserver';
// hooks
import useTransferQueryParams from 'hooks/useTransferQueryParams';
// styles
import { Content, CardWrapper, Card, CardTitle, CardText, CardButton } from './style';

const BrowseMenuSection = ({ primary, items }) => {
  const queryForTransfer = useTransferQueryParams();

  return (
    <section>
      <IntersectionObserver>
        <Content>
          <GridGallery items={items} />
          <CardWrapper>
            <Card>
              <CardTitle>{primary.title}</CardTitle>
              <CardText>{primary.text}</CardText>

              <Link scroll={false} href={{ pathname: '/checkout/2', query: queryForTransfer }}>
                <CardButton>{primary.button}</CardButton>
              </Link>
            </Card>
          </CardWrapper>
        </Content>
      </IntersectionObserver>
    </section>
  );
};

BrowseMenuSection.propTypes = {
  primary: PropTypes.object,
  items: PropTypes.array,
};

export default BrowseMenuSection;
