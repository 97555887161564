import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { Icon } from 'components/globals';
import trustPilot from 'assets/icons/star-icon.svg';
import { Content, FeedbackButton, Title } from './style';

const SliderWithNoSSR = dynamic(import('components/Slider'), {
  ssr: false,
});

const FeedbackSection = ({ slice: { primary, items } }) => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <section>
      <Content>
        <Title>{primary.title}</Title>
        {/* Trustpilot temporary hidden */}
        <Icon style={{ display: 'none' }} src={trustPilot} />
        <SliderWithNoSSR items={items} />
        <FeedbackButton onClick={handleClick}>{primary.button}</FeedbackButton>
      </Content>
    </section>
  );
};

FeedbackSection.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.object,
    items: PropTypes.array,
  }),
};

export default FeedbackSection;
