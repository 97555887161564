import styled from 'styled-components';
import { ButtonLink } from 'components/Buttons/ButtonLink';
import { device } from 'styles/device';
import { H2, H3 } from 'components/globals/TypographyElements';

export const Content = styled.div`
  z-index: 1;
  position: relative;
  height: 404px;
  margin: 74px 0 0 0;

  @media ${device.laptop} {
    margin: 61px 0 0 0;
    height: 637px;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 1220px;
  height: 100%;
  margin: 0 auto;
`;

export const Card = styled.div`
  background-color: ${({ theme }) => theme.white};
  max-width: 224px;
  padding: 33px 20px;
  text-align: center;
  margin: 0 auto;

  @media ${device.laptop} {
    max-width: 414px;
    padding: 66px 50px;
    padding: 51px 50px 66px 50px;
    margin: 0 10px 0 auto;
    text-align: left;
  }
`;

export const CardTitle = styled(H3)``;

export const CardText = styled(H2)`
  margin: 21px 0 16px 0;
  line-height: 29px;
  letter-spacing: 0;

  @media ${device.laptop} {
    margin: 34px 0 51px 0;
    line-height: 52px;
  }
`;

export const CardButton = styled(ButtonLink)`
  width: 100%;

  @media ${device.laptop} {
    width: 100%;
  }
`;
