import styled from 'styled-components';
import Image from 'components/Image';
import { device } from 'styles/device';
import { H1 } from 'components/globals/TypographyElements';

export const Content = styled.div`
  margin: 0 auto;
  max-width: 320px;
  /* padding-top: 59px; */
  position: relative;
  @media ${device.laptop} {
    display: flex;
    /* padding-top: 65px; */
    max-width: 1200px;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 35px;
  position: relative;
  z-index: 2;
  h1 {
    text-align: center;
  }
  @media ${device.laptop} {
    padding: 115px 35px;
    max-width: 430px;
    margin-left: 70px;
    h1 {
      text-align: left;
    }
  }
`;

// Trustpilot temporary hidden
export const StarsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px auto 76px auto;
  div:first-child {
    display: flex;
    justify-content: center;
    margin-bottom: 6px;
  }
  div:last-child {
    text-align: center;
  }
  @media ${device.laptop} {
    flex-direction: row;
    margin: 0;
    /* display: flex; */
    div:first-child {
      margin-right: 12px;
      svg {
        width: 28px;
      }
    }
    div:last-child {
      text-align: left;
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: auto;
  margin-right: 10px;
  min-width: 42px;
  width: 42px;
  @media ${device.laptop} {
    margin-left: 18px;
  }
`;

export const ImagesWrapper = styled.div`
  position: relative;
`;

export const FirstPlate = styled(Image)`
  filter: drop-shadow(0px 2px 12px black);
  left: -58px;
  mix-blend-mode: normal;
  /* prev values */
  /* top: -92px;
  width: 174px;
  left: -35px; */

  padding: 26px;
  position: absolute;
  top: -116px;
  width: 224px;
  z-index: 0;

  @media ${device.laptop} {
    width: 446px;
    top: auto;
    left: 4px;
    top: 222px;
  }
`;

export const SecondPlate = styled(Image)`
  filter: drop-shadow(0px 2px 12px black);
  padding: 26px;
  /* prev values */
  /* top: -106px;
  width: 165px;
  right: 0; */
  position: absolute;
  right: -26px;
  top: -132px;
  width: 218px;
  @media ${device.laptop} {
    transform: rotate(220deg);
    right: auto;
    width: 440px;
    top: -108px;
    left: 325px;
  }
`;

export const ThirdPlate = styled(Image)`
  filter: drop-shadow(0px 2px 12px black);
  transform: translate(40%, 60px);
  width: 170px;
  @media ${device.laptop} {
    width: 447px;
    transform: translate(102%, 333px);
  }
`;

export const Background = styled.img`
  display: none;
  z-index: -1;
  @media ${device.laptop} {
    display: block;
    position: absolute;
    left: -220px;
    top: 78px;
  }
`;

export const Title = styled(H1)`
  padding-bottom: 24px;
  @media ${device.laptop} {
    padding-bottom: 27px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 32px 0 0 0;
  @media ${device.laptop} {
    justify-content: start;
    margin: 28px 0 17px 0;
  }
`;
